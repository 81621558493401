// Index
// 1. Flexbox
// 2. Width Height
// 3. Text styles
// 4. Box Shadow
// 5. Scrollbar
// 6. Global chart
// 7. Loops
// 8. Snackbar
// 9. Cursor
// 10. bg colors  
// 11. Position
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
}

.montserrat {
  font-family: "Montserrat", sans-serif !important;
}

.bg-transparent {
  background-color: transparent;
}

.border-none {
  border: none !important;
}



// Flexbox
.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.d-none {
  display: none !important;
}

.d-inline-block {
  display: inline-block;
}

.fw-100 {
  font-weight: 100;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;

}

.fw-700 {
  font-weight: 700 !important;
}


.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.align-end {
  align-items: end;
}

.align-center {
  align-items: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.space-between {
  justify-content: space-between;

}

// Width Height
.w-100 {
  width: 100%;
}

// v
.w-50 {
  width: 50%;
}

.h-auto {
  height: auto;
}

.h-100vh {
  height: 100vh;
}

.h-100 {
  height: 100%;
}

// Text styles
.text-nowrap {
  white-space: nowrap;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left;
}

// Scrollbar

::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b6adad;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #F3F3F3;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}


//Loops
$spacing: 4px;

// SPACING// Margin Loop
@for $i from 0 through 12 {
  .mt-#{$i} {
    margin-top: $i * $spacing !important;
  }

  .mr-#{$i} {
    margin-right: $i * $spacing !important;
  }

  .mb-#{$i} {
    margin-bottom: $i * $spacing !important;
  }

  .ml-#{$i} {
    margin-left: $i * $spacing !important;
  }

  .my-#{$i} {
    margin-top: $i * $spacing !important;
    margin-bottom: $i * $spacing !important;
  }

  .mx-#{$i} {
    margin-right: $i * $spacing !important;
    margin-left: $i * $spacing !important;
  }

  .ml-n-#{$i} {
    margin-left: -$i * $spacing !important;
  }

  .m-#{$i} {
    margin: $i * $spacing !important;
  }
}

// Padding Loop
@for $i from 0 through 12 {
  .p-#{$i} {
    padding: $i * $spacing !important;
  }

  .pt-#{$i} {
    padding-top: $i * $spacing !important;
  }

  .pr-#{$i} {
    padding-right: $i * $spacing !important;
  }

  .pb-#{$i} {
    padding-bottom: $i * $spacing !important;
  }

  .pl-#{$i} {
    padding-left: $i * $spacing !important;
  }

  .py-#{$i} {
    padding-top: $i * $spacing !important;
    padding-bottom: $i * $spacing !important;
  }

  .px-#{$i} {
    padding-right: $i * $spacing !important;
    padding-left: $i * $spacing !important;
  }
}

// border-radius/

@for $i from 0 through 12 {
  .br-#{$i} {
    border-radius: $i * $spacing !important;
  }
}

$gap: 1px;

@for $i from 0 through 100 {
  .gap-#{$i} {
    gap: $i * $gap !important;
  }
}

//line height loop
@for $i from 0 through 12 {
  .lh-#{$i} {
    line-height: $i * $spacing !important;
  }
}

//Border Radius

@for $i from 0 through 12 {
  .border-radius-#{$i} {
    border-radius: $i * $spacing !important;
  }
}

// 3. Position
.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.inherit {
  position: inherit;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.left-50 {
  left: 50%;

}

.opacity-0 {
  opacity: 0;
}

.position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.vertical-center {
  top: 50%;
  transform: translateY(-50%);
}

.horizontal-center {
  left: 50%;
  transform: translateX(-50%);
}

// Cursor
.pointer {
  cursor: pointer;
}


// bg colors  


// Other CSS----------------------------------------
.cursor-pointer {
  cursor: pointer;
}

.w-full {
  width: 100%;
}

//disable docs controls
.docblock-argstable {
  display: none !important;
}

a {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

label {
  margin: 0;
}

// v
.z-1 {
  z-index: 1;
}


.text-end {
  text-align: end;
}

.mt-auto {
  margin-top: auto;
}


img {
  width: 100%;
  height: 100%;
  min-height: 100%;
}

.profile-email-ellipse {
  width: 168px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.object-fit-cover {
  object-fit: cover !important;
}

.validation-error {
  font-size: 14px;
  display: block;
  margin-top: 5px;
  color: #EA3829 !important;
  font-family: "Montserrat", sans-serif !important;
}

.word-break-all {
  word-break: break-all !important;
}


.capchta-highlighter {
  border: 1px solid black;
  border-radius: 100%;
  padding: 0px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 37px;
  background: #14413e;
  cursor: pointer;

  svg {
    path {
      fill: white;
    }
  }
}