@media only screen and (max-width: 1600px) {

    
.onboarding-form{
    padding: 0 30px;
}

    // .software-heading{
    //     font-size: 12px !important;
    // }

    p,
    span,
    a,
    label {
        font-size: 14px;
    }

    h2,
    .order-summary-heading {
        font-size: 20px;
    }

    h5 {
        font-size: 10px;
    }

    button {
        font-size: 14px;
    }

    .banner-carousel {
        .banner-text-wrapper {
            max-width: 385px;

            .baner-text {
                font-size: 35px;
            }
        }
    }

    .started-content {
        .started-form-wrapper {
            max-width: 441px !important;
            padding: 50px 25px !important;
        }

        .started-image-wrapper {
            max-width: 500px;
            width: 100% !important;
            margin: auto;
            height: 500px;

            .started-image {
                object-fit: contain !important;
            }
        }
    }

    .verification-text {
        font-size: 10px;
    }

    .onboarding-wrapper .onboarding-info-wrapper .order-summary-wrapper .order-plans-wrapper .plans-wrapper .subscription-card .plan-toggle-wrapper .plan-toggle-btn {
        padding: 10px;
    }

    .header-logo-text {
        font-size: 11px;
    }

    .header-logo-subheading {
        font-size: 7px;
    }

    .user-name {
        font-size: 14px !important;
    }

    .people-trustus-wrapper {
        .trust-us-left-design {
            top: 35px;
            height: 373px;
        }

        .trust-us-right-design {
            right: -105px;
            top: 395px;
            height: 473px;
        }
    }

    .newsletter-section-wrapper .newsletter-background-wrapper {
        right: -187px;
        top: -206px;
        height: 500px;
    }

    .subscription-popup {
        height: 560px !important;
        border-radius: 5px !important;
    }

    .subscription-card {
        .subs-card-subheading {
            font-size: 12px;
        }
    }
}

@media only screen and (max-width: 1500px) {}

@media only screen and (max-width: 1440px) {    
    .gallery-wrapper .product-man-wrapper{
        right: 110px;
    }
    
    .privacy-policy-section {
        max-width: 1000px !important;
    }

    .subs-contact-info {
        font-size: 10px !important;
    }

    .onboarding-wrapper .onboarding-info-wrapper .order-summary-wrapper .order-plans-wrapper .plans-wrapper .subscription-card .subscription-input input {
        width: 100px;
        padding: 0 10px !important;
    }

    .subs-mobile-icon {
        padding: 7px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        margin-right: 10px;

        i {
            font-size: 10px !important;
        }
    }
}

@media only screen and (max-width: 1260px){
    .gallery-wrapper .product-image-wrapper .product-text-wrapper .product-text{
        font-size: 12px;
    }
}

@media only screen and (max-width: 1229px) {

    .onboarding-wrapper .onboarding-header {
        padding: 16px !important;
        flex-wrap: nowrap;

        .onboarding-button {
            justify-content: flex-end !important;

            .onboarding-step-tag .step-indicators .step-indicator .tag-name:nth-child(2) {
                margin-right: 40px !important;
            }
        }
    }


    .brand-trust-card {
        padding-right: 16px !important;
        padding-left: 16px !important;
    }

    .main-header {
        padding: 12px 30px !important;

        .navbar-collapse {
            flex-grow: 1 !important;

            .navbar-nav {
                gap: 20px !important;
            }
        }
    }

    .footer-image {
        width: 230px !important;
        height: 130px !important;
    }
}

@media only screen and (max-width: 1180px) {
    .privacy-policy-section-wrapper {
        .background-two {
         display: none;
        }
      
        .left-design {
          display: none;
        }
      } 

    .onboarding-wrapper .onboarding-info-wrapper .order-summary-wrapper .order-plans-wrapper .plans-wrapper {
        .subscription-card {
            padding: 15px;
        }
    }

    .brand-trust-medium {
        padding-bottom: 22px;
    }

    .investment-form-wrapper .investment-background-wrapper {
        top: 240px;
        left: -174px;
        max-width: 377px;
    }

    .people-trustus-wrapper .trust-us-left-design {
        top: -40px;
    }


}

@media only screen and (max-width: 1099px) {

    .onboarding-wrapper .onboarding-info-wrapper .order-summary-wrapper .order-plans-wrapper .plans-wrapper .subscription-card .subscription-input input {
        width: 78px;
        padding: 0 10px !important;
    }


    .brands-wrapper {
        max-width: 950px;
    }

    .testimonial-wrapper .testimonial-card-wrapper .testimonial-card {
        padding: 60px 15px 24px;
    }


}

@media only screen and (max-width: 1050px) {

    .onboarding-wrapper .onboarding-header {
        .onboarding-step-tag .step-indicators .step-indicator .tag-name {
            margin-right: 10px !important;

            &:nth-child(2) {
                margin-right: 10px !important;
            }
        }

        .onboarding-logo {
            min-width: 45px;
            height: 45px;
            margin-right: 5px !important;
        }

        .onboarding-heading {
            padding-right: 0;
            margin-right: 15px !important;
        }

        .onboarding-save-btn {
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
    }

    .banner-carousel .banner-text-wrapper {
        max-width: 300px;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .gallery-wrapper .product-man-wrapper {
        right: 130px;
    }

    .people-trustus-wrapper .people-trust-wrapper {
        max-width: 600px;

        .people-trust {
            width: 723px;
            left: -60px;
        }

        .trust-us-design {
            transform: translate(-40%);
        }
    }
}

@media only screen and (max-width: 991px) {
    .gallery-wrapper .product-man-wrapper{
        height: 180px;
        right: 4px;
        width: auto;
    }

    .onboarding-wrapper .onboarding-info-wrapper .order-summary-wrapper .order-plans-wrapper .plans-wrapper {
        .subscription-card {
            padding: 15px 34px;
        }
    }

    .software-card-wrapper {
        padding: 10px;
    }

    .get-started-wrapper .started-wrapper {
        padding: 30px 0px !important;
    }

    .privacy-policy-section {
        padding: 30px 0px !important;
    }

    .banner-carousel .carousel {
        // z-index: -1;
    }

    .top-btn-wrapper {
        bottom: 80px !important;
    }

    .start-btn-mobile {
        display: block;
        border-radius: unset;
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 67px;
        color: #ffffff;
        z-index: 9;
        background-color: #70948f !important;
    }

    .default-header {
        display: none !important;
    }

    .banner-carousel,
    .manage-profile-background,
    .get-started-wrapper,
    .manage-subscription-wrapper {
        .header {
            position: relative !important;
            top: auto;
            right: auto;
            width: 100%;

            .main-header {
                border-radius: 0;
                padding: 12px 15px;
            }
        }

        .manage-profile-wrapper {
            padding: 30px 15px !important;
        }

        .manage-subscription {
            padding: 30px 15px !important;
        }
    }

    .main-header {
        padding: 0 !important;

        .mobile-header {
            display: block !important;
            width: 100%;

            .mobile-logo {
                padding: 14px 34px 14px 21px;
            }

            .mobile-list {
                padding: 10px 30px 10px 15px;
                background-color: #FAFAFA;

            }
        }

        .mobile-list {
            .nav-item {
                .nav-link {
                    font-size: 12px !important;
                    font-weight: 500 !important;

                    &::after {
                        display: none;
                    }
                }

                .dropdown-icon {
                    right: -15px;
                    top: 5px;
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }



    .started-content {
        .get-started-row {
            align-items: center !important;

            .started-form-wrapper {
                max-width: 500px;
                width: 100% !important;
                margin: auto;
            }

            .started-image-wrapper {
                .started-image {
                    object-fit: contain !important;
                }
            }
        }
    }

    .our-services-wrapper .services-card-wrapper .doodle-art-services {
        bottom: 100px;
    }

    .banner-carousel {
        height: 100%;
    }

    .manage-profile-pic-wrapper {
        width: fit-content;
        right: 0 !important;

        .manage-profile {
            width: 200px !important;
            top: 58px !important;
            left: -80px !important;
            z-index: 1;
        }
    }

    .banner-carousel .banner-text-wrapper {
        bottom: 22px;
        left: 15px;
    }

    .brands-wrapper {
        .brand-images-wrapper {
            max-width: 750px;
            margin: auto;
            gap: 30px;
        }
    }

    .brand-trust-medium {
        padding-bottom: 22px;
    }
}

@media only screen and (max-width: 899px) {
    .policies-wrapper-german{
        ul{
            flex-direction: column !important;
            .policies{
                border-right: none !important;
            }
        }
    }
    h1 {
        font-size: 35px;
    }

    .it-consulting {
        .right-design {
            height: 361px;
            right: -198px;
            top: 10px;
        }

        .left-design {
            left: -475px;
        }
    }

    .manage-detail-wrapper .manage-detail {
        padding: 130px 20px !important;
    }
}

@media only screen and (max-width: 800px) {

    .newsletter-background-wrapper {
        display: none;
    }

    .get-started-wrapper {
        .started-wrapper {
            background: linear-gradient(#E8F1F1, rgba(246, 246, 246, 0));

            .started-content {

                .started-form-wrapper {
                    max-width: 100% !important;
                }
            }

            .background-two {
                display: none;
            }
        }

    }

    .started-image-wrapper-column {
        display: none;
    }
}

@media only screen and (max-width: 767px) {

    .people-trustus-wrapper .people-trust-wrapper .room-client-text{
        position: unset;
        margin-top: 25px;
    }

    input ,.dropdown-button{
        height: 40px !important;
        font-size: 14px !important;
    }

    .custom-select-input {
        height: 40px !important;
    }

    .onboarding-wrapper .onboarding-info-wrapper .order-summary-wrapper .order-plans-wrapper .plans-wrapper {
        width: 100%;

        .subscription-card {
            padding: 15px 34px;
        }
    }

    .onboarding-header {
        display: none;
    }

    .onboarding-save-btn {
        height: 33px;
        font-size: 14px;
    }

    .homepage-list-item:last-child {
        margin-bottom: 0 !important;
    }

    .brand-trust-medium {
        padding-bottom: 0 !important;
    }

    .software-card {
        padding: 24px !important;
    }

    .onboarding-mobile-header {
        display: block !important;
        padding: 15px 25px;
        background-color: white;

        .onboarding-logo-wrapper {
            width: 90px;
            height: 54px;
        }

        .onboarding-mobile-steps {
            margin-top: 15px;

            .active-step-info {
                color: #8692A6;
                font-size: 12px;
                font-weight: 600;
            }

            .onboarding-step-tag {
                .step-indicators {
                    .step-indicator {
                        .tag-name {
                            .step-number {
                                padding: 5px 6px;
                                background-color: #14413E;
                                color: white;
                                font-size: 12px;
                                font-weight: 500;
                                width: 18px;
                                height: 18px;

                            }

                            .step-number-text {
                                font-size: 12px;
                                font-weight: 600;
                            }

                            &:first-child {
                                margin-right: 10px !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .butermax-subdomain-url {
        font-size: 12px !important;
        margin-right: 10px !important;
    }

    .subdomain-url {
        font-size: 12px !important;
        margin-right: 10px !important;
    }

    .btn.availablity-btn {
        font-size: 10px !important;
        max-width: 179px !important;
        padding: 0 5px !important;
        margin-top: 10px;
    }

    .banner-carousel .carousel .carousel-indicators .indicator-btn {
        height: 6px;
    }

    .services-dropdown {
        left: -14px !important;
        top: 25px !important;
    }

    .language-dropdown {
        left: -85px !important;
        top: 25px !important;
    }

    .people-trustus-wrapper {
        .people-trust-wrapper {
            .people-trust {
                .client-testimonial {
                    margin-top: 10px !important;

                    &:nth-child(1) {
                        width: 200px !important;
                        margin-left: 0 !important;
                    }

                    &:nth-child(2) {
                        width: 200px !important;
                        margin-left: 0 !important;
                    }

                    &:nth-child(3) {
                        width: 200px !important;
                        margin-left: 0 !important;
                    }

                    &:nth-child(4) {
                        width: 200px !important;
                        margin-left: 0 !important;
                    }

                    &:nth-child(5) {
                        width: 200px !important;
                        margin-left: 0 !important;
                    }

                }
            }
        }
    }

    .footer-left-border {
        border-left: 3px solid #7D806A !important;

        .footer-left-side {
            text-align: center !important;
        }
    }

    .footer-right-border {
        border-right: 3px solid #7D806A !important;

        .footer-right-side {
            .sub-heading {
                text-align: center;
            }
        }
    }

    .footer-image {
        width: 170px !important;
        height: 100px !important;
    }

    .footer-wrapper .footer-container {
        padding-bottom: 0px !important;
    }

    .people-trustus-wrapper {
        height: fit-content;

        .people-trust-wrapper {
            height: fit-content;
        }
    }

    .butlermax-training-wrapper {
        .software-option-wrapper {
            .software-option-content {
                padding-bottom: 0 !important;
            }
        }
    }

    .training-subheading {
        margin: 15px auto !important;
    }

    .butlermax-training-wrapper .offer-helping-text {
        margin: 15px auto !important;
        letter-spacing: 6px;
    }

    .software-services-wrapper {
        padding: 30px 0 20px;
    }

    .faq-section-wrapper {
        padding: 30px 0 20px;
    }

    .our-services-wrapper {
        padding: 30px 0 20px;
    }

    .gallery-wrapper {
        padding: 30px 0 20px;
    }

    .butlermax-training-wrapper {
        padding: 30px 0 20px;
    }

    .testimonial-wrapper {
        padding: 30px 0 20px;
    }

    .investment-form-wrapper {
        padding: 30px 0 20px;
    }

    .newsletter-section-wrapper {
        padding: 30px 0 20px;
    }


    .our-services-wrapper .services-card-wrapper .service-card .service-card-content {
        .service-card-text {
            text-align: center !important;
        }

        .software-btn-wrapper {
            .card-btn {
                margin: auto;
            }
        }
    }

    .testimonial-wrapper .testimonial-card-wrapper {
        margin-top: 50px;
    }

    .cards-gap {
        margin-top: 65px;
    }

    .get-started-wrapper .started-content .started-form-wrapper {
        padding: 25px 16px !important;

        .started-heading {
            width: 100%;
        }
    }

    .people-trustus-wrapper .trust-us-right-design {
        display: none;
    }

    .people-trustus-wrapper .people-trust-wrapper {
        max-width: 100% !important;
        background-image: none;

        .people-trust {
            max-width: 100% !important;
            width: 100%;
            flex-wrap: wrap;
            position: unset !important;
            align-items: baseline;
            justify-content: space-evenly !important;
        }

        .trust-us-design {
            display: none !important;
        }
    }

    .manage-detail-wrapper .manage-detail {
        padding: 30px 15px !important;

        .radio-info {
            padding: 37px 18px !important;
        }
    }


    .manage-subscription .manage-subscription-content .subscription-content {
        padding: 37px 15px 58px 15px !important;

        .plans {
            flex-direction: column;

            .subs-plan-wrapper {
                max-width: 100% !important;
            }

            .subs-change-plan-btn {
                width: 100%;
                padding: 18px !important;
                text-align: center;
                border-top-right-radius: 0px !important;
                border-bottom-right-radius: 8px !important;
                border-bottom-left-radius: 8px;

                .change-btn {
                    padding: 0 12px !important;
                }
            }
        }
    }


    .banner-carousel .carousel .carousel-indicators {
        bottom: -12px;
    }

    .product-image-section {
        flex-wrap: nowrap;
        overflow: hidden;
    }


    .gallery-wrapper .product-man-wrapper {
        display: none;
    }

    .gallery-wrapper .product-image-wrapper {
        width: fit-content;

        .product-image {
            max-height: 220px;
            max-width: 225px;
        }

        &:nth-child(2) {
            margin-top: 150px;
        }
    }

    .our-services-wrapper .services-card-wrapper .doodle-art-services {
        bottom: 330px;
    }



    .footer-wrapper {
        padding: 20px 0 31px !important;

        .footer-right-border {
            order: 2;
        }

        .footer-card-wrapper {
            margin-bottom: 20px;
        }
    }
}

@media only screen and (max-width: 700px) {
    h1 {
        font-size: 30px;
        font-weight: 500 !important;
    }

    .policy-heading{
        font-size: 16px !important;
    }

    .privacy-heading{
        font-size: 24px !important;
    }

    .onboarding-wrapper {
        .onboarding-info-wrapper .company-info-form {
            padding: 20px 15px !important;
        }

        .onboarding-catory-wrapper {
            padding: 20px 15px !important;
        }

        .radio-info {
            padding: 20px 15px !important;
        }
    }


}

@media only screen and (max-width: 601px) {
    h1 {
        font-size: 24px !important;
        font-weight: 500 !important;
    }

    .subscription-popup {
        .subs-popup-header {
            padding: 15px !important;
        }

        .popup-content-wrapper {
            padding: 15px !important;

            .select-plan-wrapper {
                .selected-plan {
                    .number-wrapper {
                        padding: 15px !important;
                    }
                }
            }

            .subscription-accordian {
                .subscription-item {
                    .accordion-collapse {
                        padding: 15px !important;

                        .accordion-body {
                            .price-wrapper {
                                flex-direction: column;
                                align-items: flex-start !important;

                                p {
                                    display: flex;
                                    align-items: center;
                                }

                                .total-price {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .subdomain-wrapper {
        flex-wrap: wrap !important;
    }

    .btn.availablity-btn {
        max-width: unset !important;
        min-width: unset !important;
        width: 100% !important;
    }


    .investment-form-wrapper {
        .heading-color {
            margin-bottom: 15px !important;
            margin-top: 0 !important;
        }

        .call-now-btn {
            margin-bottom: 15px !important;
        }
    }

    .faq-section-wrapper {
        .faq-subheading {
            margin: 15px auto !important;
        }
    }

    .our-services-wrapper .services-card-wrapper {
        margin-top: 0px;
    }

    .people-trustus-wrapper .people-trust-wrapper {
        margin: 20px auto 0;
    }


    .software-card-wrapper {
        margin-top: 0;
    }

    .services-card-mobile {
        margin-top: 84px !important;
    }

    .section-design-wrapper {
        margin: 15px auto !important;

        .section-design {
            width: 200px;
        }
    }

    .banner-text-wrapper {
        max-width: 180px !important;
        padding: 10px !important;

        .baner-text {
            font-size: 20px !important;
        }
    }

    .product-image-section {
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 0px !important;

        .product-image-wrapper {
            &:nth-child(2) {
                margin-top: 40px;
            }
        }
    }

    .butlermax-training-wrapper .offer-helping-text {
        margin: 25px 0;
        text-transform: uppercase;
        letter-spacing: 0px;
    }

    .it-consulting {
        .left-design {
            left: -608px;
        }

        .right-design {
            right: -212px;
            top: -70px;
        }
    }
}

@media only screen and (max-width: 520px) {

    .policies{
        padding: 0 10px 0 10px !important;
    }

    .updation-date{
        font-size: 12px !important;
    }

    .brand-heading {
        font-size: 14px;
        margin-bottom: 10px !important;
    }

    .newsletter-input-wrapper {
        margin-top: 20px !important;

        .newsletter-input {
            height: 50px !important;
        }

        .subscribe-btn {
            height: 50px !important;
            padding: 0 10px !important;
        }
    }

    .software-card {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .service-card {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }



    .it-consulting {
        .left-design {
            display: none;
        }

        .right-design {
            display: none;
        }
    }

    .people-trustus-wrapper .trust-us-left-design {
        display: none;
    }

    .investment-form-wrapper .investment-background-wrapper {
        display: none;
    }

    .login-wrapper {
        padding: 12px;

        .login-form-wrapper {
            padding: 15px;

            .login-heading {
                width: calc(100% - 100px);
            }
        }
    }

    .manage-profile-wrapper .manage-profile-background .manage-profile-upper {
        padding: 15px 10px 5px !important;
        border-radius: 8px !important;
    }


    .banner-text-wrapper {
        max-width: 150px !important;

        .baner-text {
            font-size: 16px !important;
        }
    }

    .tab-content-wrapper {
        .tab-content {
            padding: 15px !important;
        }
    }

    .brands-wrapper {
        padding: 10px !important;

        .brand-images-wrapper {
            gap: 10px;
        }
    }

    .our-services-wrapper .services-card-wrapper .doodle-art-services {
        left: -34px;
        bottom: 300px;
    }
}

@media only screen and (max-width: 426px) {
    h1 {
        font-size: 20px !important;
    }

    h3 {
        font-size: 16px;
    }


    label {
        font-size: 12px !important;
    }

    .thankyou-page-wrapper {
        padding: 15px !important;

        .plan-wrapper {
            padding: 10px !important;

            p {
                span {
                    font-size: 12px;
                }
            }

            .thankyou-text {
                font-size: 12px;
            }
        }
    }

    .our-services-wrapper .services-card-wrapper .doodle-art-services {
        display: none;
    }

    .newsletter-section-wrapper .social-links {
        margin-top: 30px !important;

        .text-divider .newsletter-link {
            padding: 0 7px;
        }
    }

    .subscription-popup {
        .popup-content-wrapper {
            .select-plan-wrapper {
                .selected-plan {
                    .number-wrapper {
                        padding: 10px !important;

                        span {
                            font-size: 12px;
                        }
                    }
                }

                h6 {
                    text-decoration: underline;
                    padding: 15px 0;
                }
            }
        }
    }

}

@media only screen and (max-width: 424px) {
    
    .people-trustus-wrapper .people-trust-wrapper .people-trust .client-testimonial:nth-child(1) {
        .client-testimonial-image {
            margin-left: 15px !important;
        }
    }

    .people-trustus-wrapper .people-trust-wrapper .people-trust .client-testimonial:nth-child(5) {
        .client-testimonial-image {
            margin-right: 15px !important;
        }
    }
}

@media only screen and (max-width: 410px) {
    .onboarding-wrapper .onboarding-info-wrapper .order-summary-wrapper .order-plans-wrapper .plans-wrapper .subscription-card {
        padding: 15px !important;
    }
}

@media only screen and (max-width: 400px) {

    .onboarding-mobile-header .onboarding-mobile-steps .active-step-info {
        font-size: 10px !important;
    }

    .onboarding-mobile-header .onboarding-mobile-steps .onboarding-step-tag .step-indicators .step-indicator .tag-name .step-number-text {
        font-size: 10px !important;
    }

    .onboarding-mobile-header {
        padding: 15px !important;
    }

    .onboarding-mobile-header .onboarding-mobile-steps .onboarding-step-tag .step-indicators .step-indicator .tag-name .step-number {
        margin-right: 5px !important;
        width: 15px;
        height: 15px;
    }
}

@media only screen and (max-width: 375px) {
    .policies-wrapper{
        ul{
            flex-direction: column !important;
            .policies{
                border-right: none !important;
            }
        }
    }
    }
    .manage-profile-pic-wrapper {
        right: 0px !important;

        .manage-profile {
            left: -75px !important;
        }
    }



@media only screen and (max-width: 1380px) {}